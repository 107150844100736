<template>
  <div>
  </div>
  <div class="container row mt-4" v-if="showDistricts">
    <hr/>
    <div class="card" style="border: 3px solid green;">
      <div class="card-header">
        <h3 style="color: black">Keeping</h3>
      </div>
      <div class="card-body">
        <MergeObjectDisplay
            :display-data="keepingDistDisplay"
            @valChanged="updateSubmitObject($event)"
            :index-id-value="1"
            :radio-checked="true"/>
        <!--        Users and schools-->

      </div>
      <div class="card-body">

        <div class="accordion mt-2" v-if="schoolsInKeepingOrg.length > 0"
             style="overflow-y: scroll; max-height: 100px; border: 1px solid black;">
          <h4 class="accordion-header">
            <button class="accordion-button collapsed"
                    id="keepingSchoolsHeader"
                    aria-controls="keepingSchools"
                    data-toggle="collapse"
                    data-target="#keepingSchools" style="color: black">Schools
            </button>
          </h4>
          <div id="keepingSchools" class="accordion-collapse collapse" aria-labelledby="keepingSchoolsHeader">
            <div v-for="school in schoolsInKeepingOrg" :key="school" class="card">
              <!--            <div class="card-body">{{school.schoolName}}</div>-->
              <router-link :to="{name: 'ViewSchool', params: {idSchool: school.idSchool}}">{{ school.schoolName }}
              </router-link>
            </div>
          </div>
        </div>
        <div v-else style="height: 52px; border: black 1px solid; color: black">
          No Schools Linked to this District
        </div>
        <div class="mt-2" style="border: 1px solid black; overflow-y: scroll; max-height: 125px;">

          <h5 style="color: black">Users in District</h5>
          <div>
            <h6 style="color: black">District Admins</h6>
            <UserHierarchyList :id-organization="idKeepingDistrict" :role-hierarchy="3" :org-type="2"/>
          </div>
          <div>
            <h6 style="color: black">Supervisors</h6>
            <UserHierarchyList :id-organization="idKeepingDistrict" :role-hierarchy="4" :org-type="2"/>
          </div>
          <div>
            <h6 style="color: black">Students (Para Course)</h6>
            <UserHierarchyList :id-organization="idKeepingDistrict" :role-hierarchy="5" :org-type="2"/>
          </div>
          <div>
              <h6 style="color: black">Students (Supervisor Course)</h6>
              <UserHierarchyList :id-organization="idKeepingDistrict" :role-hierarchy="6" :org-type="2" />
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="border: 3px solid red;">
      <div class="card-header">
        <h3 style="color: black;">Removing</h3>
      </div>
      <div class="card-body">
        <MergeObjectDisplay
            :display-data="removingDist"
            @valChanged="updateSubmitObject($event)"
            :index-id-value="2"
            :radio-checked="false"/>
      </div>
      <!--      Users and Schools-->
      <div class="card-body">
        <div class="accordion mt-2" v-if="schoolsInRemovingOrg.length > 0"
             style="overflow-y: scroll; max-height: 100px;">
          <h4 class="accordion-header">
            <button class="accordion-button collapsed"
                    id="removingSchoolsHeader"
                    aria-controls="removingSchools"
                    data-toggle="collapse"
                    data-target="#removingSchools">Schools
            </button>
          </h4>
          <div id="removingSchools" class="accordion-collapse collapse" aria-labelledby="removingSchoolsHeader">
            <div v-for="school in schoolsInRemovingOrg" :key="school" class="card">
              <!--            <div class="card-body">{{school.schoolName}}</div>-->
              <router-link :to="{name: 'ViewSchool', params: {idSchool: school.idSchool}}">{{ school.schoolName }}
              </router-link>
            </div>
          </div>
        </div>
        <div v-else class="container mt-2" style="height: 52px; border: black 1px solid; color: black">
          No Schools Linked to this District
        </div>
        <div class="mt-2" style="border: 1px solid black; overflow-y: scroll; max-height: 125px;">

          <h5 style="color: black">Users to be Merged</h5>
          <div>
            <h6 style="color: black">District Admins</h6>
            <UserHierarchyList :id-organization="idRemovingDistrict" :role-hierarchy="3" :org-type="2"/>
          </div>
          <div>
            <h6 style="color: black">Supervisor</h6>
            <UserHierarchyList :id-organization="idRemovingDistrict" :role-hierarchy="4" :org-type="2"/>
          </div>
          <div>
            <h6 style="color: black">Students (Para Course)</h6>
            <UserHierarchyList :id-organization="idRemovingDistrict" :role-hierarchy="5" :org-type="2"/>
          </div>
          <div>
            <h6 style="color: black">Students (Supervisor Course)</h6>
            <UserHierarchyList :id-organization="idRemovingDistrict" :role-hierarchy="6" :org-type="2" />
          </div>
        </div>
      </div>

    </div>
    <MergeResultPreview
        :preview-display="distToKeep"
        :ignore-keys="keysToIgnore"
        :display-keys="keys"
        @mergeClicked="showMergeModal = true"/>
    <div v-if="showMergeModal">
      <Teleport to="body">
        <OrgMergeModal
            :org-data="distToKeep"
            :org-type="`District`"
            :show="showMergeModal"
            @close="showMergeModal = false"
            @mergeCall="mergeHelper"/>
      </Teleport>
    </div>

  </div>
</template>

<script>
import {ref, onBeforeMount, computed, watch} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {displayDistrictById, getDistrict, mergeDistricts} from "@/Helpers/ApiCalls/DistrictAPICalls";
import Swal from "sweetalert2";
import router from "@/router";
import {isEmpty} from "lodash/lang";
import MergeObjectDisplay from "@/components/Merge/MergeObjectDisplay.vue";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList.vue";
import {prepareMergeKeys, prepareMergeObject} from "@/Helpers/MergeObjectHelper";
import MergeResultPreview from "@/components/Merge/MergeResultPreview.vue";
import OrgMergeModal from "@/components/Merge/OrgMergeModal.vue";

export default {
  name: "DistrictMerge",
  components: {OrgMergeModal, MergeResultPreview, MergeObjectDisplay, UserHierarchyList},
  props: {
    idKeepingDistrict: Number,
    idRemovingDistrict: Number
  },
  setup(props) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const selectedDistId = ref(-1);
    const removingDistId = ref(-1);
    const keepingDistDisplay = ref({});
    const removingDist = ref({});
    const removingDistIds = ref({});
    const keepingDistIds = ref({})
    const distToKeep = ref({});
    const keys = ref([]);
    const schoolsInKeepingOrg = ref([])
    const schoolsInRemovingOrg = ref([]);
    const usersInRemoving = ref([]);
    const keysToIgnore = ref(['Code', 'id', 'school', 'Count'])
    const showMergeModal = ref(false);

    onBeforeMount(() => {
      getDistricts();
    })

    watch(() => props.idKeepingDistrict, () => {
      if (props.idRemovingDistrict !== -1) getDistricts();
    })

    watch(() => props.idRemovingDistrict, () => {
      if (props.idKeepingDistrict !== -1) getDistricts();
    })

    async function getDistricts() {
      // Clear old values if we're swapping props
      keepingDistDisplay.value = {};
      removingDist.value = {};
      distToKeep.value = {};
      selectedDistId.value = -1;
      removingDistId.value = -1;

      let dist_one_tmp;
      await axios.get(displayDistrictById(props.idKeepingDistrict), {
        params: {
          showUserCount: true
        }
      }).then((result) => {
        dist_one_tmp = result.data;
        distToKeep.value = result.data;
        schoolsInKeepingOrg.value = result.data.schools.sort((a, b) => a.schoolName > b.schoolName ? 1 : -1);
        keepingDistIds.value = {
          idEsu: result.data.idEsu,
          esuName: result.data.districtEsu
        }
      });

      let dist_two_tmp;
      await axios.get(displayDistrictById(props.idRemovingDistrict), {
        params: {
          showUserCount: true
        }
      }).then((result) => {
        dist_two_tmp = result.data;
        schoolsInRemovingOrg.value = result.data.schools.sort((a, b) => a.schoolName > b.schoolName ? 1 : -1);
        removingDistIds.value = {
          idEsu: result.data.idEsu,
          esuName: result.data.districtEsu
        }
      });

      let diff_keys = Object.keys(dist_one_tmp).filter(k => dist_one_tmp[k] !== dist_two_tmp[k]);

      keys.value = prepareMergeKeys(dist_two_tmp, keysToIgnore.value);
      keepingDistDisplay.value = prepareMergeObject(dist_one_tmp, keysToIgnore.value, diff_keys);
      removingDist.value = prepareMergeObject(dist_two_tmp, keysToIgnore.value, diff_keys);
      selectedDistId.value = props.idKeepingDistrict;
      removingDistId.value = props.idRemovingDistrict;
    }

    function mergeHelper() {
      Swal.fire({
        title: 'Are You Sure?',
        text: 'This cannot be undone. Users will not be able to register under the old district.',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      }).then((res) => {
        if (res.isConfirmed) {
          commitMerge();
        }
      })
    }

    async function commitMerge() {
      await axios.put(mergeDistricts(selectedDistId.value, removingDistId.value), distToKeep.value, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: 'Successfully Merged',
            icon: 'success'
          }).finally(() => {
            router.push({name: 'ViewDistrict', params: {idDistrict: props.idKeepingDistrict}})
          })
        }
      })
    }

    const showDistricts = computed(() => {
      return !isEmpty(keepingDistDisplay.value) && !isEmpty(removingDist.value);
    })

    function updateSubmitObject(event) {
      distToKeep.value[event.key] = event.value;

      if (event.key === 'districtEsu') {
        distToKeep.value.idEsu = event.value !== keepingDistIds.value.esuName ?
            removingDistIds.value.idEsu : keepingDistIds.value.idEsu;
      }
    }

    return {
      selectedDistId,
      removingDistId,
      keepingDistDisplay,
      removingDist,
      distToKeep,
      keys,
      showDistricts,
      schoolsInKeepingOrg,
      schoolsInRemovingOrg,
      keysToIgnore,
      showMergeModal,
      mergeHelper,
      updateSubmitObject
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  max-width: 400px;
  /*margin-left: 10px;*/
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  /*max-height: 800px;*/
  /*!*height: 250px;*!*/
  /*width: 250px;*/
  /*border-radius: 0;*/
  padding: 0;
  /*border: 1px solid rgb(0, 0, 0);*/
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /*padding: 1rem 1rem;*/

}

.card-header {
  /*padding: 4% 8%;*/
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0);
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
  /*text-overflow: ellipsis;*/
}
</style>